<template>
    <div class="page-content">
        <div class="d-flex align-center">
            <h2 class="page-title">{{$store.state.language.rtds.title}}</h2>
        </div>
        <div v-for="(item, i) in $store.state.language.rtds.document" :key="i" :class="(item.element === 'video' || item.element === 'img') ? 'd-flex align-center justify-center' : ''">
            <p v-if="item.element === 'p'" v-html="item.text" :class="item.class ? item.class : ''"></p>
            <h3 v-if="item.element === 'h3'" v-html="item.text" :class="item.class ? item.class : ''" style="font-size: 23px;"></h3>
            <div class="pl-4" v-if="item.element === 'ul'">
                <ul>
                    <li v-for="(child, index) in item.childs" :key="index">
                        <p v-if="child.element === 'p'" v-html="child.text" :class="child.class ? child.class : ''"></p>
                    </li>
                </ul>
            </div>
            <img v-if="item.element === 'img'" :src="require('@/assets/img/pages/' + item.src)" :class="item.class ? item.class : ''">
        </div>
        <div class="products-section d-flex align-center justify-center">
            <div class="content d-flex flex-column w-100">
                <div class="d-flex flex-wrap ">
                    <div class="flex-col" v-for="item in $store.state.language.rtds.applications.elems" :key="item.router">
                        <div  class="software-card">
                            <div class="content">
                                <h3 class="mt-0" style="font-size: 19px; margin-bottom: 10px; font-weight: 600"><a style="color: #1f1f25;">{{item.title}}</a></h3>
                                <p class="fs-14 mb-4 app-desc">{{item.desc}}</p>
                                <a class="btn-transparent rn-btn-dark cursor-pointer" @click="$router.push(item.router)"><span class="text">{{$store.state.language.home.products.readMore}}</span></a>
                            </div>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'RTDS'
}
</script>
<style scoped>
    .page-content{
        max-width: 1300px;
        margin: 100px auto;
        padding: 0px 25px;
    }
    .page-title{
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 25px;
        letter-spacing: 5px;
        text-shadow: -2px 0px 0px rgb(251 188 17 / 0%);
    }
    .page-content p{
        color: rgba(29,29,36,.75);
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 0;
    }

    
    .software-card{
        padding: 45px 45px;
        z-index: 2;
        border-radius: 10px;
        display: flex;
        background: #f6f6f6;
        position: relative;
        flex-direction: column;
        margin: 10px;
    }
    .software-card .icon{
        font-size: 54px;
        font-weight: 400;
        margin-bottom: 23px;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        color: #e4ac16;
        display: none;
    }
    .software-card .icon svg{
        width: 54px;
        height: 54px;
            stroke-width: 1 !important;
    }
    .software-card .title{
        margin-top: 5px;
        margin-bottom: 0 !important;
        line-height: 2rem;
        letter-spacing: 0.0125em !important;
        font-size: 28px !important;
        font-weight: 500;
        font-size: 24px;
    }
    .software-card p{
            color: rgba(29, 29, 36, 0.75);
    }
    .software-card-image-div{
        height: 200px;
        position: relative;
        max-width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .software-card{
        max-width: 300px !important;
        flex: 1 auto;
        min-height: 174px;
    }
    .app-desc{
        display: flex;
        align-items: center;
        justify-self: center;
        min-height: 80px;
    }
    .flex-col{
        height: 100%;
        flex: 0 auto;
        
    }

    a.btn-transparent{
        color: #1f1f25;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding-bottom: 3px;
        transition: .3s;
        text-decoration: none;
    }
    a.btn-transparent:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 40px;
        height: 2px;
        background: #e4ac16;
        opacity: .75;
        transition: .3s;
    }
    a.btn-transparent:hover {
        color: #e4ac16;
    }
    a.btn-transparent:hover:after {
        width: 100%;
    }

</style>